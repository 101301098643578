<template>
  <div class="about-content">
    <!--    banner-->
    <div class="banner-box">
      <BannerSwipe/>
    </div>
    <div class="line-box">
      <CrossLine title="简介"/>
    </div>
    <div class="content-box" v-show="templeInfoData.templeConment">
      <p>{{templeInfoData.templeConment}}</p>
    </div>
    <div class="bottom-box"></div>
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import BannerSwipe from '@/components/Banner/BannerSwipe.vue'
import CrossLine from '@/components/common/CrossLine.vue'
import { getTempleContent } from '@/api/modules/temple'
import { mapMutations, mapState } from 'vuex'

export default {
  // import 引入的组件需要注入到对象中才能使用
  components: {
    CrossLine,
    BannerSwipe
  },
  props: {},
  // 这里用于存放数据
  data () {
    return {}
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState('temple', ['templeId','templeInfoData'])
  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {
    //获取寺庙简介
    ...mapMutations('temple',['setTempleContent']),
    async getTempleContent () {
      if(!this.templeInfoData.templeConment){
        const { data: data } = await getTempleContent(this.templeId)
        this.setTempleContent(data.data)
        this.$forceUpdate();
      }
    }

  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
    this.getTempleContent()
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.about-content{
  .banner-box{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #ffffff;
  }
  .line-box{
    position: fixed;
    top: 174px;
    left: 0;
    right: 0;
    z-index: 1;
    background: #ffffff;
  }
  .content-box{
    margin-top: 174px + 84px;
    padding: 0 15px;
    font-size: 16px;
    overflow-y: scroll;
    overscroll-behavior: contain; /* 禁止滚动溢出 */
    -webkit-overflow-scrolling: touch;
    p{
      text-indent: 2em; /* 设置缩进距离为2个空格的宽度 */
    }
  }
  .bottom-box{
    width: 375px;
    height: 60px;
  }
}

</style>
